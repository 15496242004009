{
  "name": "qogni-pwa",
  "version": "1.0.26",
  "scripts": {
    "dev": "node esbuild-dev.js",
    "dev-api": "npm run dev -- --api-root=https://dev.qogni.io/api/v1.0",
    "dev-apiproxy": "npm run dev -- --api-root=http://localhost:8181/api/azure_dev/v1.0",
    "build": "node build-cache-list.js && (node esbuild-build.js & tsc)",
    "docker-local": "docker run --rm -p 8181:80 -p 8000:8000 -v ./public:/usr/share/nginx/html qogni/pwa-boilerplate-web",
    "build-docker": "docker build -t qogni/pwa-boilerplate-web:latest -f Dockerfile ."
  },
  "dependencies": {
    "@lit-labs/virtualizer": "^2.0.14",
    "@lit/localize": "^0.12.2",
    "@pwf/pure-work": "^1.0.34",
    "@qogni-technologies/design-system": "^3.0.0",
    "@qogni-technologies/pwa-utils-library": "^1.0.0",
    "esbuild": "^0.20.0",
    "esbuild-sass-plugin": "^3.2.0",
    "lit": "^3.0.0",
    "minimist": "^1.2.8",
    "pure-web": "^1.0.39",
    "sass": "^1.77.1",
    "showdown": "^2.1.0",
    "typescript": "^5.4.5"
  },
  "devDependencies": {
    "@eslint/js": "^9.2.0",
    "@lit/localize-tools": "^0.8.0",
    "eslint": "^9.2.0",
    "eslint-plugin-lit": "^1.12.0",
    "eslint-plugin-wc": "^2.1.0",
    "glob": "^10.3.15",
    "globals": "^15.2.0"
  }
}
