import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import {
  isMobile,
  markdownToHtml,
} from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { MessagesDomain } from "../../domain/messages-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { isIterable } from "../../shared/common";
import { repeat } from "../../../node_modules/lit-html/directives/repeat";
import { msg } from "@lit/localize";

export class PageMessageThread extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #nextCursor;
  #readTimeout;
  #submitButtonRef = createRef();

  #onVisibilityChangeBound;

  constructor() {
    super();
    this.#domain = new MessagesDomain();
    this.#onVisibilityChangeBound = this.#onVisibilityChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.#getConversation();
    // this.#getMessages();

    app.notification.pollInterval = 2000;
    app.notification.addEventListener(
      "statechange",
      this.#notificationChange.bind(this)
    );

    this.#readTimeout = setTimeout(() => {
      this.#markRead();
    }, 2000);

    document.addEventListener(
      "visibilitychange",
      this.#onVisibilityChangeBound
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    app.notification.pollInterval = 10000;
    app.notification.removeEventListener(
      "statechange",
      this.#notificationChange
    );

    if (this.#readTimeout) clearTimeout(this.#readTimeout);
    document.removeEventListener(
      "visibilitychange",
      this.#onVisibilityChangeBound
    );
  }

  async #notificationChange(e) {
    if (e.detail.oldValue === undefined) return;
    await this.#getMessages(false);
    if (this.#infinityListElement)
      this.#infinityListElement.addItems(this.messages, true);
  }

  static get properties() {
    return {
      conversationId: { type: String, routeOrigin: "pathname" },
      conversation: { type: Object },
      messages: { type: Array },
      otherUser: { type: Object },
    };
  }

  async #getConversation() {
    const task = async () => {
      try {
        const response = await this.#domain.getConversionById(
          this.conversationId
        );
        this.conversation = response.data;
        this.otherUser = this.conversation.participants.filter(
          (p) => p.id !== app.session.user.id
        )[0];
      } catch (err) {
        if (err?.response?.status === 404) {
          window.location.replace("/messages");
          app.addToastMessage("Conversation not found!", { type: "error" });
          return;
        }
        window.location.replace("/messages");
        app.addToastMessage(`Conversation: ${err}`, { type: "error" });
      }
    };

    await Task.run(task);
  }

  async #getMessages(withProgressIndication = true) {
    const task = async () => {
      try {
        const response = await this.#domain.getConversionMessages(
          this.conversationId,
          { ...(this.#nextCursor && { cursor: this.#nextCursor }) }
        );
        this.messages = response.data;
        this.#nextCursor = response.pagination.next_cursor;
      } catch (err) {
        // app.addToastMessage(`Conversation Messages: ${err}`, { type: "error" });
      }
    };

    await Task.run(task, {
      global: withProgressIndication,
    });
  }

  async #markRead() {
    if (!app.notification.unreadStats["unread_messages"]) return;
    if (
      !this.messages ||
      !Array.isArray(this.messages) ||
      this.messages.length === 0
    )
      return;
    const lastMessage = this.messages[0];
    if (lastMessage)
      await this.#domain.markReadBulk(
        this.conversation.id,
        new Date(lastMessage.created_at)
      );
  }

  get #infinityListElement() {
    return this.renderRoot.querySelector("infinite-list");
  }

  render() {
    return html`
      <section class="card">
        <div class="header">
          ${this.conversation
            ? this.otherUser
              ? html`<profile-picture
                  name="${this.otherUser?.firstname} ${this.otherUser
                    ?.lastname}"
                  img="${this.otherUser?.profile_img_url}"
                  uuid=${this.otherUser?.id}
                  link=${`/profile/${
                    this.otherUser?.slug ?? this.otherUser?.id
                  }`}
                  size="50px"
                >
                </profile-picture>`
              : html`<profile-picture
                  name=${msg("Deleted user")}
                  img="/assets/img/profile-picture.webp"
                  size="50px"
                >
                </profile-picture>`
            : html`<app-shimmer class="small"></app-shimmer>`}

          <div class="info">
            ${this.conversation
              ? html`
                <div class="title">
                  <a href="/profile/${this.otherUser?.slug ?? this.otherUser?.id}">
                    ${!this.otherUser
                      ? msg("Deleted user")
                      : `${this.otherUser?.firstname} ${this.otherUser?.lastname}`}
                  </a>
                </div>
              `
              : nothing}

            ${repeat(this.otherUser?.badges ?? [], (badge) => {
              return html`<badge-tag class="small blue">
                <svg-icon icon="crown" color="white" size="12px"></svg-icon>
                ${badge.name}
              </badge-tag>`;
            })}
          </div>
          <!-- TODO: video -->
          <div
            class="actions"
            data-tooltip=${msg("Coming soon")}
            data-tooltip-position="left"
          >
            <figure class="not-available-yet">
              <svg-icon icon="video" size="100%"></svg-icon>
            </figure>
          </div>
        </div>

        <div class="message-body">
          <infinite-list
            reverse
            .renderItem=${this.#renderMessage.bind(this)}
            @scroll-end=${this.#onScrollEnd.bind(this)}
          ></infinite-list>
        </div>

        <div class="footer" ?disabled=${!this.otherUser}>
          <!--<div class="typing">Nanz is typing ...</div>-->
          <x-form @action=${this.#action}>
            <form>
              <label>
                <textarea
                  name="message"
                  type="text"
                  rows="1"
                  autocomplete="off"
                  placeholder=${msg("Type your message here...")}
                  @keydown=${this.#onMessageKeyDown}
                ></textarea>
              </label>
              <!--<figure @click="">
                  <svg-icon icon="meatballs" size="100%"></svg-icon>
                </figure>-->
              <button type="submit" ${ref(this.#submitButtonRef)}>
                <svg-icon icon="send" size="100%"></svg-icon>
              </button>
            </form>
          </x-form>
        </div>
      </section>
    `;
  }

  #onMessageKeyDown(e) {
    if (isMobile()) return;

    if (e.keyCode === 13 && (!e.shiftKey)) {
      e.preventDefault();
      this.#submitButtonRef.value.click();
    }
  }

  #renderMessage(message, idx) {
    const isMe = message.user_id === app.session.user?.id;
    const isPending = message.pending === true;
    const nextMessage = this.messages.at(idx + 1);
    const prevMessage = this.messages.at(idx - 1);
    const classList = [];
    let after = nothing;

    // Add classes to element.
    classList.push(isMe ? "sender" : "receiver");
    if (isPending) classList.push("pending");

    if (nextMessage && nextMessage.user_id !== message.user_id)
      classList.push("different-user");

    // Create HR line with day if we change the date.
    if (
      !nextMessage ||
      (message.created_at &&
        nextMessage.created_at.slice(0, 10) !== message.created_at.slice(0, 10))
    ) {
      const day = new Date(message.created_at).format({
        mode: "auto",
        rangedays: 7,
        timeStyle: undefined,
      });
      const hrElement = document.createElement("hr");

      const timeElement = document.createElement("time");
      timeElement.setAttribute("datetime", message.created_at);
      timeElement.setAttribute("data-update-interval", 30000);
      app.enhancers.run(timeElement);
      let timeElementTextContent = timeElement.textContent;

      hrElement.setAttribute("data-content", timeElementTextContent ?? day);

      timeElement.addEventListener("change", (e) => {
        timeElementTextContent = e.target.textContent;
        hrElement.setAttribute("data-content", timeElementTextContent ?? day);
      });
      after = html`${hrElement}`;
    }

    return html`
      <message-item
        class="${classList.join(" ")}"
        @touchstart=${this.#startLongPress.bind(this)}
        @touchend=${this.#stopLongPress.bind(this)}
        @touchmove=${this.#stopLongPress.bind(this)}
      >
        <section>
          <div>
            ${unsafeHTML(markdownToHtml(message.content))}
          </div>

          ${
            isPending
              ? "..."
              : html`<time
                  datetime="${message.created_at}"
                  data-update-interval="30000"
                ></time>`
          }
        </section>
        ${
          isMe
            ? html`<nav
                id="account"
                data-dropdown
                class="${isMe ? "align-right" : ""} img-button"
              >
                <button title="Menu" class="simple">
                  <svg-icon icon="meatballs"></svg-icon>
                </button>
                <menu>
                  <li class="delete">
                    <a @click=${() => this.#onDelete(message)}
                      ><svg-icon icon="trash" size="20px"></svg-icon>${msg("Delete")}</a
                    >
                  </li>
                </menu>
              </nav>`
            : nothing
        }
      </message-item>
      ${after}
    `;
  }

  async #action(e) {
    if (!e.detail.value.message) return;

    // Clear form.
    e.target.form.querySelector("textarea").value = "";

    // Scroll to top.
    this.messageBodyElement.scrollTop = this.messageBodyElement.scrollHeight;

    // Create new message.
    const promise = this.#domain.sendMessages(
      this.conversation.id,
      decodeURIComponent(e.detail.value.message)
    );

    // Add message to the list already, but with empty DB-references, shows up as pending this way.
    const messageObject = {
      id: null,
      conversation_id: this.conversation.id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      user_id: app.session.user?.id,
      content: e.detail.value.message,
      pending: true,
    };
    // Add to messages.
    // this.messages = [...this.messages, messageObject];
    this.#infinityListElement.addItems([messageObject], false, true);

    // Wait for promise to finish.
    await promise;
    // Refresh messages.
    this.#refreshMessages();

    // Scroll to top.
    this.messageBodyElement.scrollTop = this.messageBodyElement.scrollHeight;
  }

  get messageBodyElement() {
    return this.renderRoot.querySelector(".message-body");
  }

  async #refreshMessages() {
    this.#nextCursor = null;
    await this.#getMessages(false);
    if (isIterable(this.messages))
      this.#infinityListElement.addItems(this.messages, true);
  }

  async #onScrollEnd() {
    if (this.messages?.length && !this.#nextCursor) return;
    await this.#getMessages();
    if (isIterable(this.messages))
      this.#infinityListElement.addItems(this.messages, true);
  }

  async #onDelete(message) {
    const userConfirmed = await askConfirm({
      title: msg("Delete Message?", { desc: "Dialog Heading to ask the user to confirm if they want to delete a message." }),
      message: msg("Are you sure you want to delete message for everyone?", { desc: "Confirmation dialog prompt for deleting a message for all participants." }),
    });

    if (!userConfirmed) return;

    await this.#domain.deleteMessages(this.conversation.id, message.id);
    await this.#refreshMessages();
  }

  #longPressTimeout = null;

  #startLongPress(e) {
    const messageItem = e.currentTarget;

    if (!messageItem.classList.contains("sender")) return;

    this.#longPressTimeout = setTimeout(() => {
      const button = messageItem.querySelector('button[title="Menu"]');
      button.click();
    }, 500);
  }

  #stopLongPress() {
    clearTimeout(this.#longPressTimeout);
  }

  async #onVisibilityChange() {
    if (!document.hidden) {
      await this.#refreshMessages();
    }
  }
}
