import { FlowPage, UI as baseUI } from "../shared/flow-page";
import { AccountDomain } from "../domain/account-domain";
import { html } from "lit";
import { Converter } from "showdown";
import { ApiRequest } from "../shared/APIRequest";
import { msg, str } from "@lit/localize";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { repeat } from "lit/directives/repeat.js";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

const UI = {
  ...baseUI,

  chatBox: {
    ...baseUI.longtext,
    class: "chat-box auto-grow speech-enabled",
    rows: 3,
    placeholder: "Type your message...",
    stepClass: "actor-user",
    on: {
      keydown: (e) => {
        if (e.key === "Enter")
          if (!e.shiftKey) {
            e.preventDefault();
          }
      },
      keyup: (e) => {
        if (e.key === "Enter")
          if (!e.shiftKey) {
            e.target.closest("form").requestSubmit();
          }
      },
    },
  },
};

export class PageAIBuddy extends FlowPage {
  #api = ApiRequest.factory();

  flowInit(wf) {
    wf.options.strings.continue = "Send";

    // custom action
    wf.install("disclaimer", this.disclaimer.bind(this));
    wf.install("reply", this.reply.bind(this));

    wf.on("step-completed-ui-render", (e) => {
      e.detail.render = (step) => {
        return html` <div data-completed-step>
          <div class="pre-wrap">${step.value || ""}</div>
          ${this.renderTime(new Date(step.completedAt))}
        </div>`;
      };
    });
  }

  async flowStart(wf) {
    const thread = [];

    this.state = {
      user: app.session.user.firstname,
      language: app.session.user.language,
      details: this.getUserText(),
    };

    wf.disclaimer(
      msg(
        str`Note: Answers from our AI-chatbot might be incorrect. Please use caution when using, when in doubt, always ask your healthcare professional.`
      ),
      {
        local: true,
        stepClass: "disclaimer",
      }
    );

    wf.reply(msg(str`Hi ${this.state.user}, tell me what's on your mind`), {
      local: true,
      stepClass: "intro",
    });

    let topics = sessionStorage.getItem("current-health-topics");
    if (topics) topics = JSON.parse(topics);

    if (!topics) {
      topics = await Task.run(
        async () => {
          return await this.interactApi(
            `
        List 3 possible questions ${this.state.user} could ask at this point
        about their health and possible actions,
        based on what you know about them.
      `,
            {
              format: "JSON",
              memorize: false,
              template: `[
          "<Question n>"
        ]`,
            }
          );
        },
        {
          ghost: wf.ui.container,
          description: "Listing questions",
        }
      );

      sessionStorage.setItem("current-health-topics", JSON.stringify(topics));
    }

    const list = html` ${repeat(topics, (item) => html`<li>${item}</li>`)} `;

    await wf.reply(
      html`<ol @click=${this.takeSuggestion} class="tiles">
        ${list}
      </ol>`,
      {
        local: true,
      }
    );

    while (true) {
      const question = await wf.ask("", UI.chatBox);
      thread.push({
        me: question,
      });

      const answer = await wf.reply(question);

      thread.push({
        ai: answer,
      });
    }
  }

  takeSuggestion(e) {
    const text = e.target.closest("li")?.textContent.trim();

    if (text) {
      const txtArea = document.querySelector(`textarea[name="step"]`);
      if (txtArea) {
        txtArea.value = text;
        txtArea.closest("form").requestSubmit();
      }
    }
  }

  closeFlow(options) {
    options = {
      ...(options ?? {}),
      force: false,
    };
    super.closeFlow(options);
  }

  get flowType() {
    return "chat";
  }

  get icon() {
    return "search";
  }

  async reply(step) {
    const question = step.topic;

    const answer = step.options.local
      ? step.topic
      : await this.interactWithQoach(question);

    const answerHtml = html`
      <div>
        <div class="">${answer}</div>
        ${this.renderTime(new Date())}
      </div>
    `;

    step.render = () => {
      return html`${answerHtml}`;
    };
    step.resolve(answer);
  }

  async disclaimer(step) {
    step.render = () => {
      return html`${step.topic}`;
    };
    step.resolve(step.topic);
  }

  renderTime(date) {
    date = date ?? Date.now();
    return html`
      <time datetime="${date.toISOString()}">${date.toLocaleTimeString()}</time>
    `;
  }

  async interactWithQoach(message) {
    const response = await this.interactApi(message);

    if (response)
      return unsafeHTML(
        new Converter().makeHtml(response.replace(/\[doc\d+]/g, ""))
      );

    return msg("I'm not able to respond to your message.");
  }

  async interactApi(message, options) {
    options = {
      format: "string",
      memorize: true,
      temperature: 0.8,
      promptAddition: `
        Here are details about ${this.state.user}: ${this.state.details}

        NOTE: Your answer should be short and sweet, since the user can ask you later on to dive deeper.`,
      ...options,
    };

    if (options.format === "JSON") {
      options.templatePrescription = "";
      if (options.template)
        options.templatePrescription = ` using this template: ${options.template}`;

      options.formatPrescription = `
      Return JSON ${options.templatePrescription}, and return ONLY JSON, not a markdown string around it.`;

      options.promptAddition += options.formatPrescription;
    }

    const msg = `Question to react to: ${message}
  ----------------------------------

  Some personal information below (please don't react directly to any of this - simple take it in as extra context, and only try to refer to this context if your answer directly relates to stuff in this context):

  ${options.promptAddition}

  Also, please get right to the point and don't be overly verbose, nor use enthusiastic greetings or compliments. Keep it factual but a bit informal, like you know the person inside out.

  `;

    const result = await this.#api.postData(`/users/me/future-self/interact`, {
      memorize: options.memorize,
      message: msg,
    });

    const response = result.data?.response?.message;

    if (response && options.format === "JSON")
      return JSON.parse(response.trim());

    return response;
  }

  getUserText() {
    const o = AccountDomain.singleton.getHealthDetails();

    return `
User: ${o.fullName}
Diet: ${o.diet}
Age: ${o.age}

Allergies & Intolerances: ${
      !o.allergies?.length ? "none" : o.allergies.join(", ")
    }

Interest topics:
- ${app.session.user.interest_topics?.map((x) => x.name).join("\n -")}

Health intention:
${app.session.user.profile.health_intention}
`;
  }

  firstUpdated() {
    super.firstUpdated();
  }
}
